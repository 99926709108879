// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyDwQhszbhB6tuql4o3Ga3UCnkV-AbyWFCU",
  authDomain: "beemi-web.firebaseapp.com",
  projectId: "beemi-web",
  storageBucket: "beemi-web.appspot.com",
  messagingSenderId: "694312494732",
  appId: "1:694312494732:web:ba7f3bc2ec11874815fda9",
  measurementId: "G-H9EJDF8CT3"
};


const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);
// const firebaseAuth = getAuth(app);
const firestoreDb = getFirestore(app);
// const storage = getStorage(app);

// const analytics = getAnalytics(app);

export { app, firestoreDb };

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);

// // export const firestore = firebase.firestore();

// export default firebase;// TODO: Add SDKs for Firebase products that you want to use

