import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax';


export default function Layout({ children }) {
    return (
        <div className='overflow-hidden '>
            <div className="background" >
            </div>
            <div className="background-element-holder  -z-10 " >
                <div className="background-element back-piece back-piece-1 "></div>
            </div>
            <div className="background-element-holder -z-10">
                <div className="background-element back-piece back-piece-4"></div>
            </div>
            <ParallaxProvider>
                {children}
            </ParallaxProvider>
        </div>
    )
}
