import React from 'react'
import play from '../../images/play.svg'
import imgPhone from '../../images/phone.png'
import imgOne from '../../images/laptop.png'
import { useParallax } from 'react-scroll-parallax';
import pices from '../../images/torus-2.webp'



const HeroElement = () => {
    return (
        <div className="w-[100vw] md:w-[50%] md:p-20">
            <img src={imgOne} className="absolute md:w-[40vw] md:left-[50vw] left-0 top-[10vh] md:top-[25vh]" />
            <img src={imgPhone} className=" absolute w-[50%] top-[20vh] md:w-[25vw] right-[3vw] md:top-[34vh]" />
        </div>
    )
}
const Explore = () => {
    return (
        <div className="w-[100%] sm:w-[100%] md:w-[100%] lg:w-[50%] flex flex-col px-5 md:p-20 mt-[90vw] md:mt-0">
            <p className="text-4xl text-white font-[Montserrat-Bold] md:text-left text-center">
                Explore and innovate with interactive games directly within your TikTok Live sessions
                .</p>
            <div className="flex md:flex-row flex-col justify-center md:justify-between items-center pt-10 w-[100%] md:w-[50vw]">
                <a href="#download" className="header-download">Download</a>
                <div className="flex flex-row justify-center md:justify-between pt-10 md:pt-0 w-[100vw]">
                    <img src={play}  className='w-[30px] h-[30px] ml-5' />
                    <a href="/" className="text-xl font-[Montserrat-Bold] ml-5 md:ml-0 text-white min-w-[30vw]">See how Beemi works</a></div>
            </div>
        </div>
    )
}


export default function Hero() {

    const pice = useParallax({
        translateY: [-500, 300],
    });
    return (
        <div className="min-h-[100vh] px-[5vw] flex md:flex-row md:justify-around flex-col min-w-[100vw] md:items-center">
            <img className="w-[15vw] absolute left-0 -ml-[5vw] -z-10" ref={pice.ref} src={pices} />
            <Explore />
            <HeroElement />
        </div>
    )
}
