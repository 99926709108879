
import React from 'react'
import Hero from '../Elements/Hero'
import CenterElement from '../Elements/CenterElement';
import Featured from '../Elements/Featured';
import Download from '../Elements/Download';
import ProfitHoder from '../Elements/ProfitHoder';
import ContactUs from './Contact-Us';

export default function Home() {
    return (
        <div className='w-[100vw] overflow-x-hidden scroll-smooth'>
            <Hero />
            <ProfitHoder />
            <CenterElement />
            <Featured />
            <Download   id='download'/>
            <ContactUs />
        </div>
    )
}
