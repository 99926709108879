import React from 'react'
import download from '../../images/android.png'
import download1 from '../../images/appstore.png'
import download2 from '../../images/windows.png'
import { Button, message, Space, Spin } from 'antd';
import { firestoreDb } from "../../firebase";
import {
    collection,
    doc,
    getDocs,

} from "firebase/firestore";




export default function Download() {
    const [messageApi, contextHolder] = message.useMessage();
    let android = React.useRef()
    let ios = React.useRef()
    let window = React.useRef()
    const [data, setData] = React.useState(null)
    async function fetchAllDocuments() {
        const collectionRef = collection(firestoreDb, "apk_link");
        try {
            const querySnapshot = await getDocs(collectionRef);
            const documents = querySnapshot.docs.map(doc => {
                if (doc.id == 'android') {
                    android.current = doc.data().active
                }
                if (doc.id == 'ios') {
                    ios.current = doc.data().active
                }
                if (doc.id == 'window') {
                    window.current = doc.data().active
                }
            });
            setData(documents)

            return documents;  // Returns an array of documents
        } catch (error) {
            console.error("Error fetching documents:", error);
            return [];  // Return an empty array or handle the error as needed
        }
    }




    React.useEffect(() => {
        fetchAllDocuments()
    }, [])
    return (
        <div id='download' className='bg-white overflow-hidden'>
            <div className="join-section-text">
                <div className="center-text">
                    <div className='text-[#8D8D8D] font-[Montserrat-Bold] text-2xl' style={{ color: "#8D8D8D" }}>
                        Download Here
                    </div>
                </div>
            </div>
            {contextHolder}
            {data ? <div className="" >
                <div>
                    <div className='flex flex-col overflow-hidden md:flex-row justify-between px-5 md:px-[20vw] '>
                        <a href={ios.current} target="_blank" className="store" ><img src={download1} className="w-[80vw] md:w-[40vw]" /></a>
                        <a href={android.current} id="android" className="store" > <img src={download
                        } className="w-[80vw] md:w-[40vw]" /></a>
                        <a href={window.current} target='_blank' id="window" className="store" > <img src={download2} className="w-[80vw] md:w-[40vw]" /></a>

                    </div>
                    <p className='text-[gray] font-[Monstserrat-Bold] text-center p-10 text-3xl border-b-2 mx-[25%]'></p>
                </div>
            </div> : <div className='flex flex-col w-[100%] justify-center py-10'><Spin size='50' /></div>}
        </div>
    )
}
