import React from 'react'
import girl from '../../images/Girl.webp'
import pices from '../../images/piece-1.png'
import { useParallax } from 'react-scroll-parallax';



export default function CenterElement() {
    const parallax = useParallax({
        translateY: [-10, 10],
    });
    const pice = useParallax({
        translateY: [-100, 50],
    });
    return (
        <div className='-mt-[10vw]'>
            <div className="w-[100%] h-[80vh] overflow-hidden flex flex-col justify-end items-baseline">
                <div className='flex flex-col md:flex-row  items-end md:px-[10vw] overflow-hidden absolute'>
                    <div className="text-4xl font-[Montserrat-Bold] text-white text-center md:text-left md:text-[#8d0ef5] md:mb-20 md:max-w-[50%] max-w-[100%] px-10" >
                        Make your live sessions more fun and exciting.
                    </div>
                    <img className="md:w-[45%] w-[50%]" ref={parallax.ref} src={girl} />
                </div>
                <div className="w-[100vw] h-[15.8%]  -z-10 section-background-curve bg-contain bg-no-repeat  p-0 md:visible invisible">
                </div>
                <div className="w-[100%] h-[35%] bg-white -z-10 py-0 ">
                </div>
                <img className="w-[10vw] absolute" ref={pice.ref} src={pices} />
            </div>
            {/* <div className="background-container">
                <div className="section-background-curve-holder">
                    <div className="section-background-curve"></div>
                </div>
                <div className="section-background">
                </div>
                <div className="embedded-image">
                    <div className="embedded-image-holder">
                        <div className="embedded-scroller" ></div>
                        <img ref={parallax.ref} src={girl} />
                        <div className="embedded-piece"></div>
                    </div>
                </div>
            </div>
            <div className="default-center">
                <div className="mid-section-text">
                    <div className="subsection-text">
                        <div className="main-text centerd-texts" >
                            Make your live sessions more fun and exciting.
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
