import React from 'react'
import logo from "../images/bee-logo.svg";
import name from '../images/bee-name.svg'

export default function Header() {
    return (
        <div className="header shadow-lg" id="header">
            <a href="/" className="header-logo-container">
                <img src={logo} className="header-logo" />
                <img src={name} className="header-name" />
            </a>
            <div className="w-[35%] flex flex-row md:mr-10 items-center">
                <a href="/about" className="header-about">How it works?</a>
                <a href="#download" className="header-download invisible md:visible w-0 md:w-auto">Download </a>
            </div>
        </div>
    )
}
