import React from 'react'
import './App.css';
import Footer from "./components/Footer";
import Home from "./components/pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/pages/About";
import Header from "./components/Header";
import Layout from './components/Layout';
import appleAppSiteAssociationData from './.well-known/assetlinks.json'; // Import the JSON data
import Contact from './components/pages/Contact';




function App() {
  const [prevScrollPos, setPrevScrollPos] = React.useState(window.pageYOffset);


function AppleAppSiteAssociation() {
    return (
        <pre>{JSON.stringify(appleAppSiteAssociationData, null, 2)}</pre>
    );
}

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos > currentScrollPos) {
        document.getElementById("header").style.top = "0"; // Show navbar when scrolling up
      } else {
        document.getElementById("header").style.top = "-13vh"; // Hide navbar when scrolling down
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);
  return (
    <Layout >
      <Header />
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="/about" element={<About />}>
          </Route>
          <Route path="/contact" element={<Contact />}>
          </Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </Layout >
  );
}

export default App;
