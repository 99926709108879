import React from 'react'
import profit from '../../images/profit.webp'


export default function ProfitHoder() {
    return (
        <div className="flex md:flex-row flex-col  justify-between items-center px-[9vw] -z-50">
            <div >
                <img src={profit} />
            </div>
            <div className="text-4xl max-w-[80%] text-center md:text-left md:max-w-[50%] text-white font-[Montserrat-Bold] overflow-hidden">
                Earn as a creator by playing games together with your live audience.
            </div>
        </div>
    )
}
