import React from 'react'

export default function Featured() {
    return (
        <div className="flex md:flex-row justify-between  px-5 md:px-20 pt-20 pb-20 items-center flex-col ">
            <div className="text-white font-[Montserrat-Bold]">
                <div className="text-4xl font-[Montserrat-Bold] text-center" >
                    Engage your audience in a whole new way.
                </div>
                <div className="flex flex-col md:flex-row justify-center items-center pb-10">
                    <div className="flex flex-col justify-center items-center">
                        <div className="feature-image">
                            <img src={require('../../images/play-icon.webp')} />
                        </div>
                        <div className="text-4xl font-[Montserrat-Bold]">
                            Play!
                        </div>
                        <div className="text-center text-2xl font-[Montserrat-Regular]">
                            Plenty of fun games you can play with or against your audience!
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="w-[100%]">
                            <img src={require('../../images/battle-icon.webp')} />
                        </div>
                        <div className="text-4xl font-[Montserrat-Bold]">
                            Battle!
                        </div>
                        <div className="text-center text-2xl font-[Montserrat-Regular]">
                            Go against other streamers and let your audience have your back!
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                    <div className="max-w-[80%]">
                            <img src={require('../../images/host-icon.webp')} />
                        </div>
                        <div className="text-4xl font-[Montserrat-Bold]">
                            Host!
                        </div>
                        <div className="text-center text-2xl font-[Montserrat-Regular]">
                            Host fashion, karaoke, call in radio shows and have your viewers be a part of your stream.
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
